import { Auth0Provider } from '@auth0/auth0-react';
import { Configuration } from '@constants';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

export interface IAuth0State {
    returnTo?: string;
    origin?: string;
    query?: { [key: string]: string };
}

export const AuthProvider = ({
    children,
}: {
    children: ReactNode | ReactNode[];
}) => {
    const router = useRouter();
    // const history = useHistory();

    const onRedirectCallback = (appState?: IAuth0State) => {
        router.push({
            pathname: appState?.returnTo || window.location.pathname,
            query: { redirect_url: appState?.origin, ...appState?.query },
        });
    };

    return (
        <Auth0Provider
            domain={Configuration.AUTH0_DOMAIN!}
            clientId={Configuration.AUTH0_CLIENT_ID!}
            redirectUri={Configuration.AUTH0_REDIRECT_URI}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};
