import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle(
    ({ theme }) => `
  // @font-face {
  //   font-family: "IBMPlexMono";
  //   src: url("/fonts/IBMPlexMono-Medium.ttf");
  //   font-style: normal;
  //   font-weight: 500;
  //   font-display: swap;
  // }

  // @font-face {
  //   font-family: "PostGrotesk";
  //   src: url("/fonts/PostGrotesk-Light.otf");
  //   font-style: normal;
  //   font-weight: 400;
  //   font-display: swap;
  // }

  // @font-face {
  //   font-family: "PostGrotesk";
  //   src: url("/fonts/PostGrotesk-Bold.otf");
  //   font-style: normal;
  //   font-weight: bold;
  //   font-display: swap;
  // }
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap');
  html,
  body {
    color: white;
    background-color: black;
    padding: 0;
    margin: 0;
    //font-family: "${theme.fonts.primary}";
    font-family: 'Inter', sans-serif;
  }

  
  * {
    box-sizing: border-box;
    padding:0;
    margin:0;
  }

  h1{
    font-size:36px;
    font-weight:700;
    margin-bottom:16px;
  }
  h2{
    font-size:24px;
    font-weight:700;
    margin-bottom:32px;
    margin-top:34px;
    width:100%;
  }
  p{
    font-size:18px;
    
    font-weight:700;
    font-family: 'Inter', sans-serif;
    
  }
  span{
    display:block;
    font-size:14px;
    font-weight:400;
    margin-bottom:10px;
    font-family: 'Inter', sans-serif;
  }
  span.mb-32{
    margin-bottom:32px;
  }

  .cursor-p{
    cursor:pointer;
  }
  .text-dec{
    text-decoration: underline;
  }
  .img-responsive{
    max-width: 100%;
    height: auto;
 }
 .MuiButton-root{
  font-size:16px;
  font-family: 'Inter', sans-serif;
 }
 @media (max-width: 599px) {
  .MuiButton-root{
    font-size:14px;
  }
  
 }

.faqlist_wrap{
  width:100%;
}

 .faqlist_wrap .MuiAccordion-root {
  border: 1px solid #696969;
  margin-bottom: 16px;
  // padding: 20px 25px;
  border-radius: 5px;
  background:black;
  color:white;
}
.faqlist_wrap .MuiAccordion-root .MuiAccordionSummary-root{
  // padding:0px;
  padding: 20px 25px;
  min-height:auto;
}
.faqlist_wrap .MuiAccordion-root .MuiAccordionSummary-root.Mui-expanded{
  padding-bottom:0px
}
.faqlist_wrap .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content{
  margin:0px
}
.faqlist_wrap .MuiAccordion-root.Mui-expanded{
  background:#242424;
  border: 1px solid #000000;
}
.faqlist_wrap .MuiAccordion-root .MuiAccordionSummary-content.Mui-expanded{
  margin-bottom:20px;
}

.faqlist_wrap .MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root{
  // padding:0px
  padding: 0px 25px 20px;
}
.faqlist_wrap .MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root ul{
  list-style: none;

}
.faqlist_wrap .MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root ul li{
  display:flex;
}
.faqlist_wrap .MuiAccordion-root .MuiCollapse-root .MuiAccordionDetails-root ul li i{
  margin-right:11px;
}

@media (max-width: 768px) {
  .faqlist_wrap .MuiAccordion-root {
    padding: 10px 12px;
  }
}


.b-500{
  font-weight:500;
}

// loader
.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #4ca26a;
  width: 122px;
  height: 122px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin:0 auto 30px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.icon path{
  fill:#FFFFFF
}

.icon path{
  fill:#FFFFFF
}
.diconnect .MuiPaper-root {
  background-color:#343434;
  color: #fff;
  top: 80px;
  border-radius: 4px;

}
.diconnect .MuiPaper-root .MuiList-root{
  padding:0px;
}
.diconnect .MuiPaper-root .MuiList-root .MuiButtonBase-root{

padding: 12px 16px;
}

`,
);
