import { Color } from './styled';

export const Colors: Color = {
    text: '#000',
    primary: '#00b33c',
    secondary: '#0000ff',
    background: '#fff',
    imageBackground: '#CCCCCC',
    disabled: '#C4C4C4',
    border: 'rgba(194, 180, 154, 0.5)',
    info: '#C4C4C4',
    modalOverlayBackground: 'rgba(0, 0, 0, 0.6)',
    shadow: 'rgba(0, 102, 255, 0.35)',
};

export const FontStyle = {
    primary: 'IBMPlexMono',
    secondary: 'PostGrotesk'
};
