export const Configuration = {
  MOJITO_API_WS_URL: process.env.NEXT_PUBLIC_MOJITO_API_WS_URL,
  MOJITO_API_URL: process.env.NEXT_PUBLIC_MOJITO_API_URL,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_REDIRECT_URI:
    process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ||
    (typeof document !== "undefined" && document.location.origin) ||
    undefined
};

export const CLAIM_PROCESS = {
  CONNECT_WALLET: "CONNECT_WALLET",
  CONNECT_WALLET_LOADING: "CONNECT_WALLET_LOADING",
  CLAIM_CODE: "CLAIM_CODE",
  CLAIM_CODE_SUCCESFULL: "CLAIM_CODE_SUCCESFULL",
  CLAIM_CODE_ERROR: "CLAIM_CODE_ERROR",
  SIGNATURE_ERROR : 'SIGNATURE_ERROR',
  CHAIN_ERROR: 'CHAIN_ERROR',
  REEDMING_LIMIT_ERROR : 'REEDMING_LIMIT_ERROR',
  CODE_ALREADY_REEDEMED:'CODE_ALREADY_REEDEMED',
  WALLET_NOT_LOGGED_IN_ERROR : 'WALLET_NOT_LOGGED_IN_ERROR',
  POLYGON_NOT_ADDED_ERROR : 'POLYGON_NOT_ADDED_ERROR'
};

