import { ApolloProvider } from "@apollo/client";
import Navbar from "@components/navbarComponent/Navbar";
import Header from "@components/shared/Header";
import { StyledEngineProvider } from "@mui/material/styles";
import { AuthProvider } from "@services/authProvider";
import { MojitoApiProvider } from "@services/mojitoApiProvider";
import { GlobalStyles } from "@styles/GlobalStyles";
import { theme } from "@styles/theme";
import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import "../localization";
import client from "../graphql/client/index";
import Head from "next/head";

function MyApp({ Component, pageProps }: AppProps) {
  const title = "Death for Hire";
  return (
    <>
      <Head>
        <meta property="og:title" content="Death for Hire" key="title" />
        <meta
          name="description"
          content={
            'Claim your Digital Collectible (NFT) for purchasing the "Death for Hire" Platinum Bundle'
          }
        />
        <meta charSet="UTF-8" />
        <meta
          name="keywords"
          content="death for hire, nft, digital collectible, platinum bundle,claim, tokens, polygon"
        />
        <meta name="author" content="Death For Hire" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <AuthProvider>
              <GlobalStyles />
              <Component {...pageProps} />
            </AuthProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </ApolloProvider>
    </>
  );
}

export default MyApp;
